module.exports = {
  home: `Home`,
  about: `About`,
  contact: `Contact`,
  products: `Products`,
  sace: `Sace`,
  saceDigitalServices: `Sace - Digital Cloud Services`,
  saceDigitalServicesUppercased: `SACE - DIGITAL CLOUD SERVICES`,
  eArchive: `eArchive`,
  eArchiveDigitalLocker: `eArchive - your Digital Locker`,
  eArchiveDigitalLockerUppercased: `eArchive - YOUR DIGITAL LOCKER`,
  services: `Services`,
  centralizedDigitalCommunication: `Centralized Digital Communication`,
  businessProcessAutomatization: `Business Processes Automatization`,
  centralizedDigitalCommunicationUppercased: `CENTRALIZED DIGITAL COMMUNICATION`,
  businessProcessAutomatizationUppercased: `BUSINESS PROCESS AUTOMATIZATION`,
  remoteWork: `Work From Home`,
  ictSystemSolutionDesign: `Complex ICT Systems and Solutions Design`,
  remoteWorkUppercased: `WORK FROM HOME`,
  ictSystemSolutionDesignUppercased: `COMPLEX ICT SYSTEMS AND SOLUTIONS DESIGN`,
  demo: `Demo`,
  loginAsMerchant: `Login as merchant`,
  loginAsBank: `Login as bank`,
  loginAsAccountant: `Login as accountant`,
  loginAsMerchantAddress: `http://www.dsace.eu`,
  loginAsBankAddress: `http://www.dsace.eu`,
  loginAsAccountantAddress: `http://www.dsace.eu`,
  blog: `Blog`,
  digitalLockerBlog: `“Can we use Digital Locker for banking?”`,
  complaintManagementBlog: `“Customer Complaints Management`,
  lunartech: `LUNARTECH`,
  saceText1: `Secure Automated Collaboration Environment – a set of digital products and online servicesintended for micro, small and medium companies, which we offer to the market through monthly rent, in the form of finished "off the shelf" products, on the principles of shared economy.`,
  saceText2: `We are currently actively working on the completion of our own project "Development and implementation of the Digital Archive in the Cloud". The results of the project are services of digital archiving of business documentation, enriched by digitalization of business processes, the possibility of working from home and easy to connect with other participants in the process.`,
  eArchiveText1: `eArchive is the first of the implemented digital "SACE" services.`,
  eArchiveText2: `eArchive is intended for providing cloud Electronic Document Management Services (EDMS) and Digital Document Archiving Services (eArchives) to micro, small and medium business users of various profiles, in real time - seven days a week, 24 hours a day.`,
  eArchiveText3: `The solution is modular, user-oriented, prepared to connect to external (E)DMS systems if required and can be an integral part of an electronic document management platform in any corporation.`,
  eArchiveText4: ``,
  centralizedDigitalCommunicationText: `The basic functionality of the Sace platform is centralized, simple and fast communication through digital channels, realized between the users of the platform and between the users of the platform and their clients, by digitizing business and communication processes.`,
  businessProcessAutomatizationText1: `Sace platform provides users with centralized digital communication services enriched with automation and digitization of the process of electronic archiving of digital documents, monitoring of business processes, and management of marketing and online sales processes.`,
  businessProcessAutomatizationText2: `The solution is modular, user-oriented, prepared to connect to external (E)DMS systems if required and can be an integral part of an electronic document management platform in any corporation.`,
  remoteWorkText: `Supporting remote work, either from home or anywhere else, without any additional requirements for the necessary equipment other than the need to connect to the Internet, is the main goal of modern companies in their efforts to cut costs, increase efficiency, increase sales and expand customers, while preserving the environment and caring for nature.`,
  ictSystemSolutionDesignText1: `Lunartech d.o.o. also provides design, development and implementation services for complex computer and ICT systems and solutions, as well as consulting services on these same issues.`,
  ictSystemSolutionDesignText2: `Workers of Lunartech d.o.o. are specialists in the development and implementation of complex computer systems and solutions intended for the implementation of modern mobile and Internet digital payment services, digital business transformation services and electronic archiving services.`,
  ictSystemSolutionDesignText3: `Sace platform is also the base on which new Services are developed, customer support is provided and employees and users are trained.`,
  lunartechFooter1: `LUNARTECH d.o.o., Registration court: Commercial Court in Zagreb, company registration number: 081328777`,
  lunartechFooter2: `Share capital of the company: 20.000,00 kn paid in full, Director of the company: Gordana Mrković`,
  lunartechFooter3: `Tel: `,
  lunartechFooter4: ` , OIB: 44384154513, IBAN: HR5323400091111093923, e: `,
  contactUs: `CONTACT US AND FIND OUT MORE`,
  contactUppercased: `CONTACT`,
  aboutUppercased: `ABOUT`,
  telephoneNumber: `PHONE NUMBER`,
  email: `E-MAIL`,
  office: `OFFICE`,
  aboutLunartech1: `Lunartech d.o.o. is a company founded with the aim of expanding the application of digital technologies and digitalization and internetization of business of various spectrums, in companies from non-IT business areas.`,
  aboutLunartech2: `We wish to contribute to the development and well-being of our customers based on the sharing of expertise and experience gained during many years of work in the financial, security and IT industry in the domestic and international environment, in the form of External Service Provider Services.`,
  aboutLunartech3: `We specialize in providing services:`,
  aboutLunartech31: `•	Design, development and implementation of complex ICT solutions intended for digitalization of the company's business in different business zones, tailored to the needs and wishes of users, and`,
  aboutLunartech32: `•	Supervision and management of IT projects and digitization projects on behalf of users.`,
  aboutLunartech4: `We also develop our own digital products and online services, which we offer on the market in the form of finished "off the shelf" products, through our own web platform - "Sace" and on the principles of a shared economy.`,
  homeImage1: `/carousel/laptop_en_3.jpg`,
  homeImage2: `/carousel/book_en_3.jpg`,
  homeImage3: `/carousel/sace_en_3.jpg`,
  homeImage4: `/carousel/pyramid_en_3.jpg`,
  homeImage5: `/carousel/pamflet_en_3.jpg`,
  homeImage6: `/carousel/poem_en_3.jpg`,
  homeImage1Vertical: `/carousel/laptop_en_3_vertical.jpg`,
  homeImage2Vertical: `/carousel/book_en_3_vertical.jpg`,
  homeImage3Vertical: `/carousel/sace_en_3_vertical.jpg`,
  homeImage4Vertical: `/carousel/pyramid_en_3_vertical.jpg`,
  homeImage5Vertical: `/carousel/pamflet_en_3_vertical.jpg`,
  homeImage6Vertical: `/carousel/poem_en_3_vertical.jpg`,
  eTravelBookDigitalAssistant: `eTravelBook - your Digital Travel Assistant`,
  eTravelBookDigitalAssistantUppercased: `eTravelBook - YOUR DIGITAL ASSISTANT for managing travel expenses`,
  eTravelBookDigitalAssistantText1: `eTravelBook SAVES YOUR TIME AND YOUR MONEY.`,
  eTravelBookDigitalAssistantText2: `eTravelBook is a Digital Travel Expense Management and Archiving Service - a complete solution designed to create, manage, calculate and archive your travel expenses and supporting documents without the use of paper, pen and calculator.`,
  eTravelBookDigitalAssistantText31: `eTravelBook is a service that you access through the Sace platform - all you need is`,
  eTravelBookDigitalAssistantText32: `and a cell phone, tablet, laptop, or desktop computer.`,
  eTravelBookDigitalAssistantText4: `eTravelBook is a Digital Service that you access at any time and from anywhere - home, trip, office.`,
  eTravelBookDigitalAssistantText5: `eTravelBook is a Digital Service that you use to submit travel reports.`,
  eTravelBookDigitalAssistantText6: `eTravelBook is a Digital Service that you use to calculate costs and submit monthly reports to Accounting Office.`,
  eTravelBookDigitalAssistantText7: `eTravelBook KEEPS your travel documents and reports in accordance with legal needs.`,
  eTravelBookDigitalAssistantText8: `eTravelBook is YOUR DIGITAL COMPARTMENT.`,
  eTravelBookBlog: `“eTravelBook – why is eTravelBook better than the regular one?”`,
  saceUppercased: `SACE`,

  homePath: "/en",
  nextHomePath: "/",
  aboutPath: "/en/about",
  contactPath: "/en/contact",
  saceParagraphId: "Sace",
  eArchiveParagraphId: "eArchive",
  eTravelBookParagraphId: "eTravelBook",
  cdcParagraphId: "CentralizedDigitalCommunication",
  bpaParagraphId: "BusinessProcessesAutomatization",
  remoteWorkParagraphId: "WorkFromHome",
  ictParagraphId: "ICTSystemsDesign",
  nextLanguageFlagImg: "/hr_flag.jpg",
  nextLanguageFlagImgAlt: "Hrvatski"
}
